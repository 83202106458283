import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import IconChair from '../../../assets/images/dashboard/chair.svg';
import IconBox from '../../../assets/images/dashboard/box.svg';
import IconMessage from '../../../assets/images/dashboard/message.svg';
import { getLockerList, getMerchantInfo, getSeatList, merchantId_api } from '../../../lib/api';
import { numberWithCommas } from '../../common';
import { useHistory } from 'react-router-dom';
import { DashboardCard } from '../../commonStyle';
/** 좌석,사물함, 메시지 사용량 component */

const UsageData = styled.div`
  display: grid;
  grid-template-columns: 5rem auto;
  gap: 1rem;
  width: 100%;
  .titleImg {
    display: flex;
    border: 1px solid #dde4eb;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    justify-content: center;
    img {
      width: 2rem;
    }
  }
  .text {
    width: 100%;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      > button {
        font-size: 1rem;
        padding: 0.125rem 0.5rem;
        color: #91979e;
        background: none;
        border: 1px solid #dde4eb;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        &:hover {
          background-color: #f1f3f6;
        }
      }
      > p {
        margin-bottom: 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.25rem;
      }
    }
    > p {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;
      font-size: 2rem;
      color: ${(props) =>
        props.color === 'red'
          ? ' #D31F40'
          : props.color === 'orange'
          ? '#D75E1B'
          : 'var(--dashboard-default)'};
    }
  }
`;

const StyleComponent = styled(DashboardCard)`
  display: flex;
  /* height: 10vw; */
  justify-content: space-between;
  flex-flow: column;
  align-items: center;
  gap: 1rem;
  progress {
    width: 100%;
    border-radius: 0.75rem;
    height: 1.5rem;
    /* margin-bottom: 2.22vw; */
  }
  progress::-webkit-progress-bar {
    width: 100%;
    border-radius: 0.75rem;
    background: #f1f3f6;
  }
  progress::-webkit-progress-value {
    border-radius: 0.75rem;
    background: ${(props) =>
      props.color === 'red' ? '#EC6E85' : props.color === 'orange' ? '#F2A354' : '#00cfe2'};
  }
  progress::-moz-progress-bar {
    width: 100%;
    border-radius: 0.75rem;
    background: #f1f3f6;
  }
  .totalText {
    display: flex;
    p {
      font-size: 1rem;
      margin-bottom: 0;
      &:first-child {
        font-weight: 600;
      }
    }
  }
`;

const UsageComponent = ({ type, itemRef }) => {
  const [share, setShare] = useState();
  const [seatLength, setSeatLength] = useState(0);
  const [occData, setOccData] = useState(0);
  const [usingLocker, setUsingLocker] = useState(0);
  const [lockerLength, setLockerLength] = useState(0);
  const [usingMassage, setUsingMassage] = useState(0);
  const [massageLength, setMassageLength] = useState(0);
  const history = useHistory();
  const getMerchantId = async () => {
    const data = await merchantId_api();

    if (data.status === 200) {
      if (type === 'seat') seatData(data.data.id);
      else if (type === 'locker') LockerData(data.data.id);
      else messageData(data.data.id);
    }
  };
  const seatData = async (e) => {
    let data = await getSeatList(e);
    if (data.status === 200) {
      let usingFilterData = data.data.filter((item) => item.isUsing === 1);
      setSeatLength(data.data.length);
      setOccData(usingFilterData.length);
      let usingSeatData = {
        oneTicket: 0,
        dayTicket: 0,
        timeTicket: 0,
      };
      for (let i = 0; i < usingFilterData.length; i++) {
        if (usingFilterData[i].ticketType === 1) {
          usingSeatData.oneTicket += 1;
        } else if (usingFilterData[i].ticketType === 2) {
          usingSeatData.dayTicket += 1;
        } else if (usingFilterData[i].ticketType === 3) {
          usingSeatData.timeTicket += 1;
        }
      }

      setShare(
        Math.floor(
          (data.data.filter((item) => item.isUsing === 1).length / data.data.length) * 100,
        ),
      );
    }
  };

  const LockerData = async (e) => {
    let data = await getLockerList(e);

    if (data.status === 200) {
      setLockerLength(data.data.length);
      setUsingLocker(data.data.filter((item) => item.userId).length);
      setShare(
        Math.floor((data.data.filter((item) => item.userId).length / data.data.length) * 100),
      );
    }
  };

  const messageData = async (e) => {
    const res = await getMerchantInfo(e);

    if (res.status === 200) {
      setMassageLength(res.data[0].maxSendMessage);
      setUsingMassage(res.data[0].sendMessageUseCount);
      setShare(Math.floor((res.data[0].sendMessageUseCount / res.data[0].maxSendMessage) * 100));
    }
  };

  const goMessagePage = () => {
    history.push('/message/charge');
  };
  useEffect(() => {
    getMerchantId();
  }, []);

  return (
    <Fragment>
      <StyleComponent
        ref={itemRef}
        color={
          type === 'message' && share > 89
            ? 'red'
            : type === 'message' && share > 49
            ? 'orange'
            : 'nomal'
        }>
        <UsageData
          color={
            type === 'message' && share > 89
              ? 'red'
              : type === 'message' && share > 49
              ? 'orange'
              : 'nomal'
          }>
          <div className="titleImg">
            <img
              src={type === 'seat' ? IconChair : type === 'locker' ? IconBox : IconMessage}
              alt="icon"
            />
          </div>
          <div className="text">
            <div>
              <p>
                {type === 'seat'
                  ? '좌석 점유율'
                  : type === 'locker'
                  ? '사물함 점유율'
                  : '메시지 사용량'}
              </p>
              {type === 'message' && <button onClick={() => goMessagePage()}>충전</button>}
            </div>

            <p>{share ? share : 0}%</p>
          </div>
        </UsageData>
        <progress
          max={type === 'seat' ? seatLength : type === 'locker' ? lockerLength : massageLength}
          value={type === 'seat' ? occData : type === 'locker' ? usingLocker : usingMassage}
        />
        <div className="totalText">
          <p>
            {type === 'seat'
              ? `${occData}석 사용중`
              : type === 'locker'
              ? `${usingLocker}개 사용중`
              : `${numberWithCommas(usingMassage)}개 사용`}
          </p>{' '}
          <p>
            / 총{' '}
            {type === 'seat'
              ? `${seatLength}석`
              : type === 'locker'
              ? `${lockerLength}개`
              : `${numberWithCommas(massageLength)}개`}
          </p>
        </div>
      </StyleComponent>
    </Fragment>
  );
};

export default UsageComponent;
