import React, { useState } from 'react';
import { useEffect } from 'react';
import { Input, Modal, ModalBody, ModalHeader } from 'reactstrap';
import styled from 'styled-components';
import { Button, Label1 } from '../../pages/lockerChange';
import { ModalBackground } from './choiceModal';
import configDB from '../../data/customizer/config';
import Swal from 'sweetalert2';
import { Dropdown } from '../common/Dropdown';

const primary = localStorage.getItem('default_color') || configDB.data.color.primary_color;

const AddTimeBar = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 20%;
  }
  p {
    margin: 0 8px 0 4px;
    font-size: 15px;
  }
  select {
    width: 30%;
  }
`;
const AddTimeModal = ({
  isOpen,
  data,
  close,
  time,
  day,
  payType,
  amount,
  timeValue,
  dayValue,
  payTypeValue,
  amountValue,
  check,
  signData,
}) => {
  const [hour, setHour] = useState(1);
  const [min, setMin] = useState(0);
  const [sign, setSign] = useState('+');

  const cancle = () => {
    close();
    check(false);
    time(null);
    day(null);
    payType('cash');
    amount(0);
  };

  const setData = () => {
    check(false);
    time(null);
    day(null);
    payType('cash');
    amount(0);
  };

  const changeSec = () => {
    signData(sign);
    time(hour * 3600 + min * 60);
  };
  useEffect(() => {
    setData();
  }, []);

  const addTimeAndDayModal = () => {
    if (
      ((data.type === 1 || data.type === 3) && hour * 3600 + min * 60 === 0) ||
      ((data.type === 2 || data.type === 5) && !dayValue) ||
      !amountValue
    ) {
      Swal.fire({
        icon: 'error',
        title: '모든 값을 입력해주세요',
        timer: 3500,
      });
    } else {
      changeSec();
      check(true);
      close();
    }
  };
  return (
    <>
      <Modal centered isOpen={isOpen} toggle={cancle}>
        <ModalHeader className="modal-title" toggle={cancle}>
          시간/기간 수정
        </ModalHeader>
        <ModalBody>
          {data.type === 1 || data.type === 3 ? (
            <>
              <h5 style={{ fontSize: '14px' }}>추가 시간</h5>
              <Label1>
                <AddTimeBar>
                  <Input
                    className="form-control"
                    type="text"
                    name="time"
                    value={hour || ''}
                    onChange={(e) => setHour(e.target.value)}
                  />
                  <p>시</p>
                  <Input
                    className="form-control"
                    type="text"
                    name="time"
                    value={min || ''}
                    onChange={(e) => setMin(e.target.value)}
                  />
                  <p>분</p>
                  <Dropdown>
                    <select value={sign} onChange={(e) => setSign(e.target.value)}>
                      <option value="+" defaultChecked>
                        추가
                      </option>
                      <option value="-">차감</option>
                    </select>
                  </Dropdown>
                </AddTimeBar>
              </Label1>
            </>
          ) : (
            <Label1>
              <h5>추가 일자</h5>
              <Input
                className="form-control"
                type="text"
                name="day"
                value={dayValue || ''}
                onChange={(e) => day(e.target.value)}
              />
            </Label1>
          )}

          <Label1>
            <h5>금액</h5>
            <Input
              className="form-control"
              type="number"
              name="amount"
              value={amountValue || ''}
              onChange={(e) => amount(e.target.value)}
            />
          </Label1>
          <Label1>
            <h5>결제 종류</h5>
            <Dropdown>
              <select onChange={(e) => payType(e.target.value)}>
                <option value="cash">현금</option>
                {/* <option value="kakaopay">카카오페이</option> */}
                <option value="virtual">가매입/가매출</option>
              </select>
            </Dropdown>
          </Label1>
          <div
            style={{
              display: 'flex',
              width: '100%',
              gap: '10px',
            }}>
            <Button color="#fff" onClick={cancle}>
              취소
            </Button>
            <Button onClick={() => addTimeAndDayModal()} color={primary}>
              변경
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <ModalBackground />
    </>
  );
};

export default AddTimeModal;
