import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import IconUser from '../../../assets/images/dashboard/people.svg';
import { PieChart } from 'react-minimal-pie-chart';
import { getSeatList, merchantId_api } from '../../../lib/api';
import { DashboardCard } from '../../commonStyle';
import theme from '../../../theme';

const UserChartData = styled.div`
  display: grid;
  grid-template-columns: 5rem auto;
  gap: 1rem;
  width: 100%;
  .titleImg {
    display: flex;
    border: 1px solid #dde4eb;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    justify-content: center;
    img {
      width: 2rem;
    }
  }
  .text {
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > button {
        font-size: 1rem;
        line-height: 18px;
        color: #91979e;
        background: none;
        border: 1px solid #dde4eb;
        border-radius: 100px;
      }
      > p {
        margin-bottom: 0;
        font-weight: 700;
        line-height: 1.5;
        font-size: 1.25rem;
      }
    }
    > p {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;

      font-size: 2rem;
      color: ${(props) =>
        props.color === 'red'
          ? ' #D31F40'
          : props.color === 'orange'
          ? '#D75E1B'
          : 'var(--dashboard-default)'};
    }
  }
`;

const StyleComponent = styled(DashboardCard)`
  display: flex;

  /* height: 10vw; */
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  width: 100%;

  .totalText {
    display: flex;
    p {
      font-size: 1rem;
      margin-bottom: 0;
      &:first-child {
        font-weight: 600;
      }
    }
  }

  .chartData {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    justify-items: center;
    gap: 1rem;
    > div {
      .chart {
        width: 4rem;
      }
      /* width: 26.66vw; */
      display: flex;
      align-items: center;
      gap: 0.5rem;
      > p {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0;
        color: var(--dashboard-default);
      }
    }
  }

  .flex {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: space-between;
  }
`;

const LabelData = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.82vw;

  width: fit-content;
  row-gap: 0.25rem;
  column-gap: 2rem;
  > div {
    display: flex;
    align-items: center;
    span {
      &:first-child {
        width: 1rem;
        border-radius: 50px;
        height: 6px;
        margin-right: 1rem;
      }
      &:last-child {
        font-size: 1rem;
        color: #686c71;
        line-height: 1.5;
      }
    }
  }
`;
const TicketUserComponent = ({ isMobile }) => {
  const [seatLength, setSeatLength] = useState(0);
  const [usingData, setUsingData] = useState([]);
  const getMerchantId = async () => {
    const data = await merchantId_api();

    if (data.status === 200) {
      seatData(data.data.id);
    }
  };

  const seatData = async (e) => {
    let data = await getSeatList(e);

    if (data.status === 200) {
      let usingFilterData = data.data.filter((item) => item.isUsing === 1);
      setSeatLength(data.data.length);
      let usingSeatData = {
        oneTicket: 0,
        dayTicket: 0,
        timeTicket: 0,
      };

      for (let i = 0; i < usingFilterData.length; i++) {
        if (usingFilterData[i].ticketType === 1) usingSeatData.oneTicket += 1;
        if (usingFilterData[i].ticketType === 2) usingSeatData.dayTicket += 1;
        if (usingFilterData[i].ticketType === 3) usingSeatData.timeTicket += 1;
      }
      console.log(usingSeatData);
      setUsingData(usingSeatData);
    }
  };

  useEffect(() => {
    getMerchantId();
  }, []);

  return (
    <Fragment>
      <StyleComponent>
        <div className="flex">
          <UserChartData>
            <div className="titleImg">
              <img src={IconUser} alt="icon" />
            </div>
            <div className="text">
              <div>
                <p>입장권별 회원 수</p>
              </div>
              <p>{usingData.oneTicket + usingData.dayTicket + usingData.timeTicket || 0}명</p>
            </div>
          </UserChartData>
        </div>

        <div className="chartData">
          <div>
            <div className="chart">
              <PieChart
                data={[
                  {
                    title: '',
                    value: Math.floor((usingData.oneTicket / seatLength) * 100) || 0,
                    color: theme.normalSeatWithDisposableTicket,
                  },
                ]}
                reveal={Math.floor((usingData.oneTicket / seatLength) * 100) || 0}
                rounded
                animate
                startAngle={270}
                lineWidth={isMobile ? 26.66 : 20}
                background={'#F1F3F6'}
                label={({ dataEntry }) => dataEntry.value + '%'}
                labelStyle={{
                  fontSize: '1.25rem',
                  fill: theme.normalSeatWithDisposableTicket,
                  fontWeight: 600,
                }}
                labelPosition={0}
              />
            </div>
            <p>{usingData.oneTicket || 0}명</p>
          </div>
          <div>
            <div className="chart">
              <PieChart
                data={[
                  {
                    title: '',
                    value: Math.floor((usingData.timeTicket / seatLength) * 100) || 0,
                    color: theme.normalSeatWithTimeBasedTicket,
                  },
                ]}
                reveal={Math.floor((usingData.timeTicket / seatLength) * 100) || 0}
                rounded
                animate
                startAngle={270}
                lineWidth={isMobile ? 26.66 : 20}
                background={'#F1F3F6'}
                label={({ dataEntry }) => dataEntry.value + '%'}
                labelStyle={{
                  fontSize: '1.25rem',
                  fill: theme.normalSeatWithTimeBasedTicket,
                  fontWeight: 600,
                }}
                labelPosition={0}
              />
            </div>
            <p>{usingData.timeTicket || 0}명</p>
          </div>
          <div>
            <div className="chart">
              <PieChart
                data={[
                  {
                    title: '',
                    value: Math.floor((usingData.dayTicket / seatLength) * 100) || 0,
                    color: theme.normalSeatWithPeriodicTicket,
                  },
                ]}
                reveal={Math.floor((usingData.dayTicket / seatLength) * 100) || 0}
                rounded
                animate
                startAngle={270}
                lineWidth={isMobile ? 26.66 : 20}
                background={'#F1F3F6'}
                label={({ dataEntry }) => dataEntry.value + '%'}
                labelStyle={{
                  fontSize: '1.25rem',
                  fill: theme.normalSeatWithPeriodicTicket,
                  fontWeight: 600,
                }}
                labelPosition={0}
              />
            </div>

            <p>{usingData.dayTicket || 0}명</p>
          </div>
        </div>
        <LabelData>
          <div>
            <span style={{ background: theme.normalSeatWithDisposableTicket }} />{' '}
            <span>일회권</span>
          </div>
          <div>
            <span style={{ background: theme.normalSeatWithTimeBasedTicket }} />{' '}
            <span>시간정액권</span>
          </div>
          <div>
            <span style={{ background: theme.normalSeatWithPeriodicTicket }} /> <span>기간권</span>
          </div>
        </LabelData>
      </StyleComponent>
    </Fragment>
  );
};

export default TicketUserComponent;
