import React from 'react';
import styled from 'styled-components';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  itemsRightSideTitle?: JSX.Element[];
}

const PageContainer = ({ title, itemsRightSideTitle, children }: Props) => {
  return (
    <PageContent>
      {(title || itemsRightSideTitle) && (
        <PageTitleArea>
          {title && <h1>{title}</h1>}
          {itemsRightSideTitle && <RightSideTitleArea>{itemsRightSideTitle}</RightSideTitleArea>}
        </PageTitleArea>
      )}
      {children}
    </PageContent>
  );
};

export default PageContainer;

const PageTitleArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 767px) {
    /* flex-direction: column; */
    gap: 1rem;
    h1 {
      align-self: flex-start;
    }
    > div {
      align-self: flex-start;
    }
  }
`;

const RightSideTitleArea = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const PageContent = styled.div`
  width: 100%;
  padding: 1.5rem;
  margin-bottom: 1.5rem;
  /* width: calc(100% - 5rem);
  padding: 2.5rem;
  margin: 2.5rem;
  border-radius: 1.5rem; */
  background: white;

  box-shadow: 0px 2px 8px rgba(34, 34, 34, 0.15);

  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  > div {
    border-radius: 0;
  }

  h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
  }

  @media screen and (max-width: 767px) {
    margin: 0;
    margin-bottom: 1.5rem;
    padding: 1rem;
    border-radius: 0;
  }
`;
