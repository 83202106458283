import React, { Fragment, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot, useRecoilValue } from 'recoil';
import './index.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';
import { BrowserRouter, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { merchantUserRoute, routes, termsRoute, userRoute, v1Route, v2Route } from './route';
import ConfigDB from './data/customizer/config';
// Authentication
import Signin from './auth/signin';
import SignUp from './auth/signup';

import { CookiesProvider } from 'react-cookie';
import MerchantList from './pages/merchantList';

import FindPassword from './auth/password';
import ReactGA from 'react-ga';
import PageBarcode from './pages/barcode';

import WebReceipt from './pages/webReceipt';
import Modals from './components/atom/Modals';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import ModalsProvider from './store/ModalsProvider';
import GlobalStyle from './theme/global';
import { getAllToken } from './util/token';
import { merchantKioskVersionStore, userPhoneNumberState } from './state';
import { registerLocale } from 'react-datepicker';

const ScrollToTop = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0); // 페이지 이동 시 스크롤 위치를 맨 위로 설정
    });

    // 컴포넌트가 언마운트될 때 이벤트 리스너를 정리
    return () => {
      unlisten();
    };
  }, [history]);

  return null;
};

const Root = (props) => {
  const [anim, setAnim] = useState('');
  const animation = localStorage.getItem('animation') || ConfigDB.data.router_animation || 'fade';
  const abortController = new AbortController();

  const merchantKioskVersion = useRecoilValue(merchantKioskVersionStore);
  const userPhoneNumber = useRecoilValue(userPhoneNumberState);
  const { userToken, merchantToken, adminToken } = getAllToken();

  const jwt_token = userToken || merchantToken || adminToken;

  const UserType = useMemo(() => {
    return {
      admin: !!adminToken && !merchantToken,
      adminMerchant: !!merchantToken && !!adminToken,
      merchantUserV1: !!merchantToken && !userToken && !!userPhoneNumber,
      merchantUserV2: !!merchantToken && !!userToken,
      userV2: !!userToken && !merchantToken,
      merchant: !!merchantToken && !userToken,
    };
  }, [userToken, merchantToken, adminToken, userPhoneNumber]);

  const TRACKING_ID = process.env.React_APP_GOOGLE_ANALYTICS_TRACKING_ID;
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    setAnim(animation);
    localStorage.setItem('isClick', false);
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
    // eslint-disable-next-line
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const Routes = useMemo(() => {
    const { userV2, merchantUserV1, merchantUserV2, admin, adminMerchant } = UserType;
    if (userV2) return userRoute;
    if (merchantUserV1 || merchantUserV2) return merchantUserRoute;
    if (admin) return [];
    return routes;
  }, [UserType]);

  const MerchantVersionRoutes = useMemo(() => {
    try {
      if (merchantKioskVersion > 1) return v2Route;
      return v1Route;
    } catch (error) {
      return v1Route;
    }
  }, [merchantKioskVersion]);

  return (
    <Fragment>
      <ModalsProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Provider store={store}>
            <BrowserRouter basename={`/`}>
              <ScrollToTop />
              <Modals />
              <Route path={`${process.env.PUBLIC_URL}/merchant-list`} component={MerchantList} />
              {!jwt_token ? (
                <Switch>
                  <Route path={`${process.env.PUBLIC_URL}/auth/login`} component={Signin} />
                  <Route path={`${process.env.PUBLIC_URL}/auth/join`} component={SignUp} />
                  <Route
                    path={`${process.env.PUBLIC_URL}/auth/password`}
                    component={FindPassword}
                  />
                  <Route path={`${process.env.PUBLIC_URL}/receipt/:token`} component={WebReceipt} />
                  <Route
                    path={`${process.env.PUBLIC_URL}/barcode/:usercode`}
                    component={PageBarcode}
                  />

                  {termsRoute.map(({ path, Component }) => (
                    <Route
                      key={path}
                      exact
                      path={`${process.env.PUBLIC_URL}${path}`}
                      component={Component}></Route>
                  ))}
                  <Route
                    path="*"
                    exact
                    render={() => (
                      <Redirect
                        to={
                          UserType.merchant || UserType.adminMerchant
                            ? '/admin/dashboard'
                            : UserType.userV2
                            ? '/select-merchant'
                            : UserType.admin
                            ? '/merchant-list'
                            : UserType.merchantUserV1 || UserType.merchantUserV2
                            ? '/dashboard'
                            : '/auth/login'
                        }
                      />
                    )}
                  />
                </Switch>
              ) : (
                <App isLoading={isLoading}>
                  <TransitionGroup>
                    <Switch>
                      {[...Routes, ...MerchantVersionRoutes].map(({ path, Component }) => (
                        <Route key={path} exact path={`${process.env.PUBLIC_URL}${path}`}>
                          {({ match }) => (
                            <CSSTransition
                              in={match != null}
                              timeout={100}
                              classNames={anim}
                              unmountOnExit>
                              <div>
                                <Component setIsLoading={setIsLoading} />
                              </div>
                            </CSSTransition>
                          )}
                        </Route>
                      ))}
                      <Route
                        path="*"
                        render={() => (
                          <Redirect
                            to={
                              UserType.merchant || UserType.adminMerchant
                                ? '/admin/dashboard'
                                : UserType.userV2
                                ? '/select-merchant'
                                : UserType.admin
                                ? '/merchant-list'
                                : UserType.merchantUserV1 || UserType.merchantUserV2
                                ? '/dashboard'
                                : '/auth/login'
                            }
                          />
                        )}
                      />
                    </Switch>
                  </TransitionGroup>
                </App>
              )}
            </BrowserRouter>
          </Provider>
        </ThemeProvider>
      </ModalsProvider>
    </Fragment>
  );
};

ReactDOM.render(
  <RecoilRoot>
    <CookiesProvider>
      <Root />
    </CookiesProvider>
  </RecoilRoot>,
  document.getElementById('root'),
);

serviceWorker.unregister();
