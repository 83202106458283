import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { paySimpleLogList } from '../../../lib/api';
import { numberWithCommas } from '../../common';
import { DashboardCard } from '../../commonStyle';

const StyleComponent = styled(DashboardCard)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > p {
      margin-bottom: 0;
      font-weight: 700;
      line-height: 1.5;
      font-size: 1.25rem;
    }
    > button {
      font-size: 1rem;
      padding: 0.125rem 0.5rem;
      color: #91979e;
      background: none;
      border: 1px solid #dde4eb;
      border-radius: 100px;
      margin-left: 0.5vw;
      display: flex;
      align-items: center;
      &:hover {
        background-color: #f1f3f6;
      }
    }
  }
  .data-cards {
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    height: 100%;
    gap: 0.5rem;
  }
`;

const PayDataCard = styled.div`
  background-color: #f1f3f6;
  padding: 1rem;
  border-radius: 0.62vw;
  /* margin: 0.83vw 0; */

  > p {
    font-weight: 600;
    font-size: 1rem;
    color: #686c71;
  }
  p {
    margin-bottom: 0;
  }
  .dateAndPrice {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    div {
      display: flex;
      color: #a6adb5;
      align-items: center;
    }
    .date {
      font-size: 0.75rem;
      color: #a6adb5;
    }
    .price {
      font-weight: 600;
      font-size: 1rem;
      color: var(--dashboard-default);
    }
  }
`;
const SimplePayData = ({ mid }) => {
  const history = useHistory();
  //오늘
  const [startDate, setStartDate] = useState(new Date());
  //금액
  const [payPrice, setPayPrice] = useState();

  const getDates = (dateString) => {
    // 입력받은 문자열을 moment 객체로 변환합니다.
    const date = moment(dateString, 'YY/MM/DD'); // 해당 주의 첫번째 날짜를 구합니다.
    const firstDay = date.clone().startOf('isoWeek'); // 해당 주의 마지막 날짜를 구합니다.
    const lastDay = date.clone().endOf('isoWeek'); // 첫번째 날짜와 마지막 날짜를 YY/MM/DD 형식의 문자열로 변환합니다.
    const firstMonthDay = date.clone().startOf('month');
    const lastMonthDay = date.clone().endOf('month');
    const firstDayStr = firstDay.format('MM.DD');
    const lastDayStr = lastDay.format('MM.DD');
    const firstMonthStr = firstMonthDay.format('MM.DD');
    const lastMonthStr = lastMonthDay.format('MM.DD');
    return [firstDayStr, lastDayStr, firstMonthStr, lastMonthStr];
  };

  const goDayPay = () => {
    history.push('/sales/day');
  };

  const getPayData = async () => {
    const payData = await paySimpleLogList(mid);
    try {
      if (payData.status === 200) {
        setPayPrice(payData.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (mid) getPayData();
  }, [mid]);
  return (
    <Fragment>
      <StyleComponent>
        <div className="flex">
          <p>간단 매출 현황</p>
          <button onClick={() => goDayPay()}>자세히</button>
        </div>
        <div className="data-cards">
          <PayDataCard>
            <p>오늘</p>
            <div className="dateAndPrice">
              <p className="date">{moment(startDate).format('MM.DD')}</p>
              <p className="price">{numberWithCommas(payPrice?.now)}원</p>
            </div>
          </PayDataCard>
          <PayDataCard>
            <p>이번 주</p>
            <div className="dateAndPrice">
              <div>
                <p className="date">{getDates(startDate)[0]}</p>-
                <p className="date">{getDates(startDate)[1]}</p>
              </div>
              <p className="price">{numberWithCommas(payPrice?.week)}원</p>
            </div>
          </PayDataCard>
          <PayDataCard>
            <p>이번 달</p>
            <div className="dateAndPrice">
              <div>
                <p className="date">{getDates(startDate)[2]}</p>-
                <p className="date">{getDates(startDate)[3]}</p>
              </div>
              <p className="price">{numberWithCommas(payPrice?.month)}원</p>
            </div>
          </PayDataCard>
        </div>
      </StyleComponent>
    </Fragment>
  );
};

export default SimplePayData;
