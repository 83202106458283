import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

// user
export const userLevelState = atom({
  key: 'userLevelState',
  default: null,
});

export const userPhoneNumberState = atom({
  key: 'userPhoneNumState',
  default: '',
  effects_UNSTABLE: [persistAtom],
});

export const merchantKioskVersionStore = atom({
  key: 'merchantKioskVersionStore',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

// const merchantIdState = atom({
//   key: "merchantIdState",
//   default: "",
// });
// const authLevelState = atom({
//   key: "authLevelState",
//   default: "",
// });
