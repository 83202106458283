import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Loader from '../layout/loader';
import Header from '../layout/header';
import { ToastContainer } from 'react-toastify';
import { getMerchantInfo, merchantId_api } from '../lib/api';
import RouteChangeTracker from './routeChangeTracker';
import { BackgroundLoading } from './Loading';
import { SideBar2 } from '../layout/sidebar/index';
import { getInfo } from '../lib/api/v2/user';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { merchantKioskVersionStore, userPhoneNumberState } from '../state';
import { getAllToken } from '../util/token';
import { getMerchantKioskVersion } from '../lib/api/v2/merchant';
import FooterV2 from '../layout/footer/FooterV2';
import useWindow from '../lib/hooks/useWindow';
import PageBody from '../layout/page/PageBody';

const App = ({ children, isLoading }) => {
  const windowSize = useWindow();

  const ScreenType = useMemo(() => {
    if (!windowSize || !windowSize.actualWidth) return 'desktop';
    if (windowSize.actualWidth >= 1280) return 'desktop';
    if (windowSize.actualWidth >= 768) return 'tablet';
    return 'mobile';
  }, [windowSize]);

  const isMobile = useMemo(() => {
    if (!windowSize) return false;
    return windowSize.width && windowSize.width < 1280;
  }, [windowSize]);
  const [merchantData, setMerchantData] = useState({});
  const [mid, setMid] = useState({});
  const [isShowSide, setIsShowSide] = useState(false);

  const setMerchantKioskVersion = useSetRecoilState(merchantKioskVersionStore);
  const [userPhoneNumber] = useRecoilState(userPhoneNumberState);

  useEffect(() => {
    getMerchantId();
  }, []);

  const { userToken, merchantToken, adminToken } = getAllToken();
  const level = localStorage.getItem('level');

  const UserType = useMemo(() => {
    return {
      admin: adminToken && !merchantToken,
      adminMerchant: !!merchantToken && !!adminToken,
      merchantUserV1: !!merchantToken && !userToken && !!userPhoneNumber,
      merchantUserV2: !!merchantToken && !!userToken,
      userV2: !!userToken && !merchantToken,
      merchant: !!merchantToken && !userToken,
    };
  }, [userToken, merchantToken, adminToken, userPhoneNumber]);

  const getMerchantId = async () => {
    try {
      const htmlTitle = document.querySelector('title');

      if (UserType.merchantUserV1 || UserType.merchantUserV2) {
        const { data } = await getInfo();
        setMerchantData({ ...data });
        return;
      }

      if (UserType.adminMerchant) {
        const { data } = await getInfo();
        setMerchantData({ ...data });

        try {
          const kioskVersionData = await getMerchantKioskVersion();
          const { outerVersion } = kioskVersionData.data;
          const version = outerVersion.split('.');
          setMerchantKioskVersion(parseInt(version[0]));
        } catch (error) {
          setMerchantKioskVersion(1);
        }

        return;
      }

      // 지점 및 관리자 로그인
      if (!UserType.userV2 && !UserType.admin)
        try {
          const { data } = await merchantId_api();
          const kioskVersionData = await getMerchantKioskVersion();

          const { outerVersion } = kioskVersionData.data;
          const version = outerVersion.split('.');
          setMerchantKioskVersion(parseInt(version[0]));
          setMid(data.id);
          if (data) {
            const res = await getMerchantInfo(data.id);

            if (res.status === 200) {
              setMerchantData(res.data[0]);
              if (UserType.adminMerchant || UserType.merchant) {
                htmlTitle.innerText = `렛미업 스터디카페 키오스크 - ${res.data[0].name} 관리페이지`;
              }
            }
          }
          return;
        } catch (error) {
          console.log(error);
          setMerchantKioskVersion(1);
        }

      const parsedPhoneNumber =
        userPhoneNumber && userPhoneNumber.length > 4 ? userPhoneNumber.slice(-4) : '';
      // 회원 로그인
      // const res = await getInfo();
      setMerchantData({ ...merchantData, phoneNum: userPhoneNumber || '' });
      htmlTitle.innerText = `렛미업 스터디카페 키오스크${
        parsedPhoneNumber ? ` - ${parsedPhoneNumber}` : ''
      }`;
    } catch (err) {
      console.log(err);
    }
  };

  RouteChangeTracker();

  return (
    <Fragment>
      <Loader />
      {isLoading && <BackgroundLoading />}
      {UserType.admin && (
        <div
          onClick={() => {
            if (localStorage.getItem('isClick')) {
              const selected = document.querySelector('.onhover-show-div');
              if (!selected) return;
              document.querySelector('.onhover-show-div').classList.remove('showing');
              localStorage.setItem('isClick', false);
            }
          }}
          className="page-body-wrapper sidebar-icon">
          <SideBar2 isShowSide={isShowSide} setIsShowSide={setIsShowSide} />
          <div className="page-body" isLoading merchantData={merchantData}>
            {children}
          </div>
        </div>
      )}
      {!UserType.admin && (
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          {!UserType.admin && (
            <Header
              isMobile={isMobile}
              screenType={ScreenType}
              data={merchantData}
              mid={mid}
              isShowSide={isShowSide}
              setIsShowSide={setIsShowSide}
            />
          )}

          <div
            onClick={() => {
              if (localStorage.getItem('isClick')) {
                const selected = document.querySelector('.onhover-show-div');
                if (!selected) return;
                document.querySelector('.onhover-show-div').classList.remove('showing');
                localStorage.setItem('isClick', false);
              }
            }}
            className="page-body-wrapper sidebar-icon">
            {/* <Sidebar/> */}
            <SideBar2 isShowSide={isShowSide} setIsShowSide={setIsShowSide} isMobile={isMobile} />
            {/* <div className="page-body" isLoading merchantData={merchantData}> */}
            <PageBody>{children}</PageBody>

            {/* </div> */}
            {level === 'merchant' && <FooterV2 data={merchantData} isMobile={isMobile} />}
            {/* <ThemeCustomize /> */}
          </div>
        </div>
      )}

      <ToastContainer />
    </Fragment>
  );
};

export default App;
