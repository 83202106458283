import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { Form, Row } from 'reactstrap';
import { X } from 'react-feather';
import { MENUITEMS } from '../sidebar/menu';
import LeftHeader from './leftbar';
import RightHeader from './rightbar';
import { Link } from 'react-router-dom';
import { Loading } from '../../constant';
import { useSelector } from 'react-redux';

const Header = (props) => {
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setsearchValue] = useState('');
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);
  const layout_type = useSelector((content) => content.Customizer.layout);
  const layout_version = useSelector((content) => content.Customizer.mix_background_layout);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue('');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  const handleSearchKeyword = (keyword) => {
    keyword ? addFix() : removeFix();
    const items = [];
    setsearchValue(keyword);
    mainmenu.map((menuItems) => {
      menuItems.Items.filter((mItems) => {
        if (mItems.title.toLowerCase().includes(keyword) && mItems.type === 'link') {
          items.push(mItems);
        }
        if (!mItems.children) return false;
        mItems.children.filter((subItems) => {
          if (subItems.title.toLowerCase().includes(keyword) && subItems.type === 'link') {
            subItems.icon = mItems.icon;
            items.push(subItems);
          }
          if (!subItems.children) return false;
          subItems.children.filter((suSubItems) => {
            if (suSubItems.title.toLowerCase().includes(keyword)) {
              suSubItems.icon = mItems.icon;
              items.push(suSubItems);
            }
            return suSubItems;
          });
          return subItems;
        });
        checkSearchResultEmpty(items);
        setsearchValue(items);
        return mItems;
      });
      return menuItems;
    });
  };

  const checkSearchResultEmpty = (items) => {
    if (!items.length) {
      setSearchResultEmpty(true);
      document.querySelector('.empty-menu').classList.add('is-open');
    } else {
      setSearchResultEmpty(false);
      document.querySelector('.empty-menu').classList.remove('is-open');
    }
  };

  const addFix = () => {
    setSearchResult(true);
    document.querySelector('.Typeahead-menu').classList.add('is-open');
    document.body.className = `${layout_version} ${layout_type} offcanvas`;
  };

  const removeFix = () => {
    setSearchResult(false);
    setsearchValue('');
    document.querySelector('.Typeahead-menu').classList.remove('is-open');
    document.body.className = `${layout_version} ${layout_type}`;
  };
  return (
    <Fragment>
      <div className="page-header">
        <Row className="header-wrapper m-0">
          {/* <LeftHeader data={props.data} mid={props.mid} /> */}
          <RightHeader
            screenType={props.screenType}
            data={props.data}
            mid={props.mid}
            isShowSide={props.isShowSide}
            setIsShowSide={props.setIsShowSide}
          />
        </Row>
      </div>
    </Fragment>
  );
};

export default Header;
