import moment from 'moment';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getMerchantInfo, merchantId_api } from '../../lib/api';

import ChannelService from './channelTalk';
import UsageComponent from './adminDashboard/usageComponent';
import TicketUserComponent from './adminDashboard/ticketUserComponent';
import SalesChart from './adminDashboard/saleChart';
import NoticeListTable from './adminDashboard/NoticeList';
import SimplePayData from './adminDashboard/simplePayData';
import QuickMenu, { MobileQuickMenu } from './adminDashboard/quickMenu';
import { useRef } from 'react';
import TicketSale from './adminDashboard/ticketSale';
import useWindow from '../../lib/hooks/useWindow';

// const LinkBtns = styled.div`
//   a {
//     box-shadow: rgb(0 0 0 / 20%) 0px 2px 14px -3px;
//     margin-right: 1vw;
//     button {
//       background-color: #ffffff !important ;
//       border: none;
//       color: #04c9db;
//     }
//     button:hover {
//       background-color: #04c9db !important;
//       border-color: #04c9db !important;
//       color: #ffffff;
//     }
//   }

//   @media screen and (max-width: 768px) {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     gap: 10px;
//     a {
//       button {
//         width: 100%;
//         border-radius: 0.25rem;
//       }
//     }
//   }
// `;
// const FirstLine = styled.div`;
//   display: flex;
//   margin-top: 30px;
//   justify-content: space-between;
//   > div:nth-child(2) {
//     width: 57%;
//   }
//   @media screen and (max-width: 768px) {
//     flex-direction: column;
//     .card {
//       width: 100%;
//     }
//     > div:nth-child(2) {
//       width: 100%;
//     }
//   }
// `;
// const NoticeContainer = styled.div`
//   padding: 30px;
// `;

// const FlexContainer = styled.div`
//   width: 100%;
//   display: grid;
// `;

const ScrollBasis = styled.span`
  /* display: none; */
`;

// const FlexColunmContainer = styled.div`
//   justify-content: space-between;
//   display: flex;
//   flex-direction: column;
//   .grid {
//     display: grid;
//     grid-template-columns: 2fr 1fr;
//     gap: 1.1vw;
//   }
// `;

// const GridContainer = styled.div`
//   width: 100%;
//   display: grid;
//   gap: 1.1vw;
//   height: fit-content;
//   > div {
//     width: 100%;
//     gap: 1.1vw;
//     display: grid;
//     &:first-child {
//       grid-template-columns: 1fr 1fr 1fr 2fr;
//     }
//     &:nth-child(2) {
//       grid-template-columns: 2fr 0.5fr;
//     }
//   }
// `;

const MobileContainer = styled.div`
  width: 100%;
  padding: 1rem;
  /* margin: 4.44vw auto; */
  display: grid;

  gap: 1rem;
`;
const TabletContainer = styled.div`
  width: 100%;
  padding: 1rem;
  /* margin: 4.44vw auto; */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
`;
const DesktopContainer = styled.div`
  width: 100%;
  padding: 1rem;
  /* margin: 4.44vw auto; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
`;

const TabContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;

  gap: 1rem;
  padding: 1rem;
  justify-content: space-between;
  /* position: fixed; */
  /* padding: 0 calc((103vw - 86.92vw) / 2); */
  background-color: #f1f3f6;
  align-items: center;
  z-index: 1;
  button {
    height: 3rem;
    background-color: #ffffff;
    border-radius: 1.5rem;
    color: #91979e;
    border: 1px solid #dde4eb;
    font-weight: 500;
    font-size: 1.25rem;
    padding: 0;
    width: 100%;
    &.check {
      background-color: var(--dashboard-default);
      color: #ffffff;
    }
  }
`;

// const CenterContainer = styled.div`
//   width: 100%;
//   height: calc(100vh - 6.56vw);
//   margin-top: calc(4.16vw - 6.24vw);
//   display: flex;
//   align-items: center;
// `;
const AdminDashboard = ({ setIsLoading }) => {
  const windowSize = useWindow();

  const [merchantData, setMerchantData] = useState({});

  const mobileStatisticsRef = useRef(null);
  const mobileSalesRef = useRef(null);
  const mobileAnnouncesRef = useRef(null);
  const tabletStatisticsRef = useRef(null);
  const tabletSalesRef = useRef(null);
  const tabletAnnouncesRef = useRef(null);

  const mobileScrollRefs = [mobileStatisticsRef, mobileSalesRef, mobileAnnouncesRef];
  const tabletScrollRefs = [tabletStatisticsRef, tabletSalesRef, tabletAnnouncesRef];

  const [selectContent, setSelectContent] = useState(0);
  const tabRef = useRef([]);
  const [mid, setMid] = useState();
  const [startDate, setStartDate] = useState(moment(new Date()).startOf('month')._d);
  const [endDate, setEndDate] = useState(moment(new Date()).endOf('month')._d);

  let level = localStorage.getItem('level');

  const getMerchantData = async () => {
    const { data } = await merchantId_api();

    if (data) {
      const res = await getMerchantInfo(data.id);
      setMid(data.id);
      if (res.status === 200) {
        setMerchantData(res.data[0]);
      }
    }
  };

  const ScreenType = useMemo(() => {
    if (!windowSize || !windowSize.width) return 'desktop';
    if (windowSize.width >= 1280) return 'desktop';
    if (windowSize.width >= 768) return 'tablet';
    return 'mobile';
  }, [windowSize]);

  const SalesChartGridCss = useMemo(() => {
    if (!windowSize || !windowSize.width) return 4;
    if (windowSize.width >= 1920) return 2;
    if (windowSize.width >= 1440) return 3;
    return 4;
  }, [windowSize]);

  const tabArray = [
    { name: '통계', tab: 0 },
    { name: '매출', tab: 1 },
    { name: '공지', tab: 2 },
  ];

  const moveContent = (tab) => {
    if (ScreenType === 'mobile') {
      mobileScrollRefs[tab].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
    if (ScreenType === 'tablet') {
      tabletScrollRefs[tab].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }

    setSelectContent(tab);
  };

  useEffect(() => {
    if (level === 'user') {
      window.location.href = `${window.location.origin}/dashboard`;
    }
    getMerchantData();
    setTimeout(() => setIsLoading(false), 1000);
  }, []);

  useEffect(() => {
    if (merchantData) {
      ChannelService.boot({
        pluginKey: 'c7244504-2def-4fd9-b399-3336a3cbcceb', //please fill with your plugin key
        memberId: merchantData.merchant_code,
        profile: {
          name: merchantData.name,
          mobileNumber: merchantData.contact,
          merchant: merchantData.name,
          phoneNum: merchantData.contact,
        },
      });
    }
  }, [merchantData]);

  return (
    <>
      <Fragment>
        {/* <Breadcrumbs parent="대시보드" title="대시보드" /> */}
        {/* 1920 */}
        {ScreenType === 'tablet' && (
          <>
            <TabContainer>
              {tabArray.map((item) => (
                <button
                  className={selectContent === item.tab ? 'check' : ''}
                  onClick={() => moveContent(item.tab)}>
                  {item.name}
                </button>
              ))}
            </TabContainer>
            <TabletContainer>
              <UsageComponent type="seat" itemRef={tabletScrollRefs[0]} />
              <UsageComponent type="locker" />
              <UsageComponent type="message" />
              <MobileQuickMenu isMulti={merchantData.multiKioskFloor} mid={mid} />
              <TicketUserComponent />
              <TicketSale
                mid={mid}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <SimplePayData mid={mid} />
              <NoticeListTable isMobile={false} itemRef={tabletScrollRefs[2]} />
              <SalesChart
                style={{ gridColumn: '1 / span 2' }}
                mid={mid}
                // isMobile={isMobile}
                paddingStyle={'none'}
                setIsLoading={setIsLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                itemRef={tabletScrollRefs[1]}
              />
            </TabletContainer>
          </>
        )}
        {ScreenType === 'desktop' && (
          <DesktopContainer>
            <UsageComponent type="seat" />
            <UsageComponent type="locker" />
            <UsageComponent type="message" />
            <QuickMenu isMobile={false} isMulti={merchantData.multiKioskFloor} mid={mid} />
            <TicketUserComponent />

            <TicketSale
              mid={mid}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
            <SimplePayData mid={mid} />
            <NoticeListTable isMobile={false} />
            <SalesChart
              style={{ gridColumn: `1 / span ${SalesChartGridCss}` }}
              mid={mid}
              paddingStyle={'none'}
              setIsLoading={setIsLoading}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
            />
          </DesktopContainer>
        )}
        {/* mobile */}
        {ScreenType === 'mobile' && (
          <>
            <TabContainer>
              {tabArray.map((item) => (
                <button
                  className={selectContent === item.tab ? 'check' : ''}
                  onClick={() => moveContent(item.tab)}>
                  {item.name}
                </button>
              ))}
            </TabContainer>
            <MobileContainer>
              {/* <ScrollBasis ref={(el) => (tabRef.current[0] = el)} /> */}
              <MobileQuickMenu
                isMulti={merchantData.multiKioskFloor}
                mid={mid}
                itemRef={mobileScrollRefs[0]}
              />
              <UsageComponent type="seat" />
              <UsageComponent type="locker" />
              <UsageComponent type="message" />
              <TicketUserComponent isMobile={true} />
              <SimplePayData mid={mid} />
              <SalesChart
                mid={mid}
                paddingStyle={'none'}
                setIsLoading={setIsLoading}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                itemRef={mobileScrollRefs[1]}
              />
              <TicketSale
                mid={mid}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <NoticeListTable isMobile={true} itemRef={mobileScrollRefs[2]} />
            </MobileContainer>
          </>
        )}
      </Fragment>
    </>
  );
};

export default AdminDashboard;
