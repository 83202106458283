import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { SeatArrayS, SeatView, TabButton, Text, Wall } from '../../pages/seatList';

import { getMerchantInfo } from '../../lib/api';
import CommonWrapper from './CommonWrapper';
import { getSeatList } from '../../lib/api/v2/seat';
import { swalReturnWarning } from '../../util/commonFunctions';
import useModal from '../../lib/hooks/useModal';
import { SeatV2 } from '../common/Seat';

const Container = styled.div`
  width: 60rem;
  max-width: 100%;
  overflow: scroll;
  height: 75vh;
`;

export const SelectBox = styled.div`
  width: 100%;
  position: relative;
  select option {
    padding: 4vw 1vw;
  }
  img {
    width: 1vw;
    position: absolute;
    transform: rotate(-90deg);
    top: 44%;
    right: 3.8vw;
  }
`;

const SeatMapviewModal = ({
  onClose,
  show,
  merchantId,
  ticketItemId,
  selected,
  setChoiceSeatId,
  setChoiceSeatNum,
}) => {
  const { closeAllModal } = useModal();

  const [lists, setLists] = useState([]);
  const [walls, setWalls] = useState([]);
  const [texts, setTexts] = useState([]);
  const [defaults, setDefaults] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [floorNum, setFloorNum] = useState(null);
  const [multiNum, setMultiNum] = useState(null);

  const CurrentFloorSeat = useMemo(() => {
    if (!multiNum) return lists;
    return lists.filter((item) => item.floor === floorNum);
  }, [lists, multiNum, floorNum]);

  const CurrentFloorWall = useMemo(() => {
    if (!multiNum) return walls;
    return walls.filter((item) => item.floor === floorNum);
  }, [walls, multiNum, floorNum]);

  const CurrentFloorText = useMemo(() => {
    if (!multiNum) return texts;
    return texts.filter((item) => item.floor === floorNum);
  }, [texts, multiNum, floorNum]);

  const getSeatListApi = async () => {
    try {
      const res = await getSeatList(ticketItemId);
      const { data } = await getMerchantInfo(merchantId);

      if (res.status === 200) {
        if (!res.data || res.data.length === 0) {
          swalReturnWarning('해당 이용권으로 사용할 수 있는 좌석이 없습니다.');
          return closeAllModal();
        }

        if (data[0].multiKioskFloor) {
          setFloorNum(1);
          setMultiNum(data[0].multiKioskFloor);
          setDefaults(res.data);
          setLists(res.data.filter((li) => li.type === 'seat'));
          setWalls(res.data.filter((li) => li.type === 'wall'));
          setTexts(res.data.filter((li) => li.type === 'text'));
        } else {
          setLists(res.data.filter((li) => li.type === 'seat'));
          setWalls(res.data.filter((li) => li.type === 'wall'));
          setTexts(res.data.filter((li) => li.type === 'text'));
        }
      }
    } catch (error) {
      swalReturnWarning('좌석 목록을 가져올 수 없습니다.\n잠시 후 다시 시도해 주세요.');
      closeAllModal();
    }
  };
  useEffect(() => {
    if (!ticketItemId) return;
    getSeatListApi();
  }, [ticketItemId]);

  const handleSelectSeat = (seat) => {
    setChoiceSeatId(seat.id);
    setChoiceSeatNum(seat.number);
    onClose();
  };

  // const changefloor = (floorData) => {
  //   if (floorNum) {
  //     setLists(
  //       defaults.filter(
  //         (item) =>
  //           item.type === 'seat' &&
  //           selected?.T_level >= item?.level &&
  //           item.seatType === 0 &&
  //           item.floor === floorData,
  //       ),
  //     );
  //     setWalls(defaults.filter((li) => li.type === 'wall' && li.floor === floorData));
  //     setTexts(defaults.filter((li) => li.type === 'text' && li.floor === floorData));
  //   }
  // };
  const ratio = (920 - window.innerWidth) * 0.7 * 0.001;
  // const isMinimal = window.innerWidth < 480;\
  const isMinimal = false;
  const isFold = window.innerWidth < 768;
  // useEffect(() => {
  //   // if (floorNum !== null) {
  //   //   changefloor(floorNum);
  //   // }
  // }, [floorNum]);
  return (
    // <Modal
    //   centered
    //   isOpen={show}
    //   toggle={handleClose}
    //   style={{ maxWidth: isFold ? '90vw' : '70vw', margin: '2vw auto' }}>
    //   <ModalHeader className="modal-title" toggle={handleClose}>
    //     좌석 선택
    //   </ModalHeader>
    <CommonWrapper title="좌석 선택">
      <Container>
        {multiNum && (
          <div className="floorBtn">
            {[...Array(multiNum)].map((item, index) => (
              <TabButton
                selected={floorNum === index + 1}
                value={index + 1}
                onClick={() => setFloorNum(index + 1)}>
                {index + 1}층
              </TabButton>
            ))}
          </div>
        )}
        <SeatView ratio={ratio} isMin={isMinimal}>
          <SeatArrayS ratio={ratio} isMin={isMinimal}>
            {CurrentFloorSeat?.map((seat) => (
              <React.Fragment key={seat.id}>
                {(seat.status === 'block' || seat.status === 1) && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    used="#979797"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'idle' && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    onClick={() => handleSelectSeat(seat)}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'using' && seat.seatType === 1 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    used="#1BBABC"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'using' && seat.seatType === 0 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    used={
                      seat.ticketType === 1
                        ? '#616161'
                        : seat.ticketType === 2
                        ? '#FF8F00'
                        : '#00838F'
                    }
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'rest' && seat.seatType === 1 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    used="#7bb4ff"
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'rest' && seat.seatType === 0 && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    className="seat"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    used={
                      seat.ticketType === 1
                        ? '#B0BEC5'
                        : seat.ticketType === 2
                        ? '#FFAB91'
                        : '#80DEEA'
                    }
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
                {seat.status === 'using' && seat.type === 'seminar' && (
                  <SeatV2
                    ratio={ratio}
                    isMin={isMinimal}
                    used="#616161"
                    top={seat.pos_top}
                    left={seat.pos_left}
                    {...seat}>
                    <p>{seat.number}</p>
                  </SeatV2>
                )}
              </React.Fragment>
            ))}
            {CurrentFloorWall?.map((wall) => (
              <React.Fragment key={wall.id}>
                <Wall
                  ratio={ratio}
                  isMin={isMinimal}
                  width={wall.pos_width}
                  height={wall.pos_height}
                  top={wall.pos_top}
                  left={wall.pos_left}
                />
              </React.Fragment>
            ))}
            {CurrentFloorText?.map((text) => (
              <React.Fragment key={text.id}>
                <Text
                  ratio={ratio}
                  isMin={isMinimal}
                  size={text.font_size}
                  top={text.pos_top}
                  left={text.pos_left}>
                  {text?.name}
                </Text>
              </React.Fragment>
            ))}
          </SeatArrayS>
        </SeatView>
      </Container>
    </CommonWrapper>
    // </Modal>
  );
};
export default SeatMapviewModal;
