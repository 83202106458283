import React, { Fragment, useEffect } from 'react';
import { Card, CardBody, Container, Form, Input } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import { useState } from 'react';

import styled from 'styled-components';
import moment from 'moment';
import {
  getSeminarInfoList,
  getSeminarItemList,
  getUserData,
  userTicketBlock,
} from '../../lib/api';
import DataTable from 'react-data-table-component';
import { seminarReserved1 } from '../../data/ticketList';
import PaginationComponent from '../../components/pagination';
import Swal from 'sweetalert2';
import { LabelRow } from '../ticketCustom';
import { getInfo } from '../../lib/api/v2/user';
import Table, { TableHeaderContainerV2 } from '../../components/common/Table';
import PageContainer from '../../components/common/PageContainer';
import Search from '../../components/common/Search';
import { Dropdown } from '../../components/common/Dropdown';

const TopObject = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  > div {
    display: flex;
    align-items: center;
  }
  width: 100%;
  margin-bottom: 10px;
  select {
    background: #f8fcff;
    border: 1px solid #e9e9e9;
    border-radius: 4px;
    padding: 9px 12px;
    font-size: 14px;
  }
  .seminar_name {
    width: 150px;
  }
  .m-0 {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* position: relative; */
    width: 100%;

    input {
      width: 80% !important;
    }
    .fa-search {
      position: absolute;
      right: 12px;
      top: 16.8px;
    }
  }
  @media screen and (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
    > div {
      width: 100%;
      flex-direction: column;
    }
    select {
      padding: 9px 5px;
    }
    .seminar_name {
      width: 100%;
    }
    .m-0 {
      margin: 3vw 0 !important;
      input {
        width: 100% !important;
      }
    }
  }
`;

const SeminarInfo = () => {
  const [mid, setMid] = useState('');
  const [phoneNum, setPhoneNum] = useState('');

  const [startDate, setStartDate] = useState(new Date('2020-01-01'));
  const [endDate, setEndDate] = useState(new Date('2030-01-01'));
  const [reservationData, setReservationData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPageAdd, setPostsPerPageAdd] = useState(10);
  const [postsPerPageAdd1, setPostsPerPageAdd1] = useState(10);
  const [pageNum, setPageNum] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(10);
  const indexOfLast = currentPage * postsPerPage;
  const indexOfFirst = indexOfLast - postsPerPage;
  const [seminarData, setSeminarData] = useState([]);
  const [seminarId, setSeminarId] = useState();
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [defaultList, setDefaultList] = useState([]);

  const getData = async () => {
    const { data } = await getInfo();

    setMid(data.merchantId);
    setPhoneNum(data.phoneNum);
    getSeminarData(data.merchantId);
    getSeminarList(data.merchantId);
  };

  const getSeminarData = async (e) => {
    let data = await getSeminarInfoList(e);

    if (data.status === 200) {
      setSeminarData(data.data);
      setSeminarId(data.data[0].id);
    }
  };

  const getSeminarList = async (e, timedata) => {
    let data = await getSeminarItemList(
      e,
      moment(startDate).format('YYYYMMDD000000'),
      moment(endDate).format('YYYYMMDD235959'),
    );
    //(data);
    if (e !== undefined && data.status === 200) {
      let changeData = [
        data.data,
        data.data.map((item) => {
          item.start = moment(item.startDate).format('YYYY-MM-DD HH:mm:ss');
          item.end = moment(item.endDate).format('YYYY-MM-DD HH:mm:ss');
          item.cancel = (
            <div className="btn btn-danger" onClick={() => reservationCancel(item)}>
              예약취소
            </div>
          );
        }),
      ];
      setReservationData(
        changeData[0]
          .filter((item) => item.phoneNum === phoneNum)
          .filter((item) => +item.seminarId === +seminarId),
      );

      setDefaultList(changeData[0].filter((item) => item.phoneNum === phoneNum));
    }
  };

  const reservationCancel = async (i) => {
    Swal.fire({
      title: '세미나실 예약을 취소 하시겠습니까?',
      text: '예약취소를 하실경우 환불은 관리자에게 문의해주세요',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#F53C14',
      cancelButtonColor: '#04c9db',
      confirmButtonText: '예약취소',
      cancelButtonText: '취소',
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await userTicketBlock({
          ticketId: i.id,
          merchantId: i.merchantId,
          block: !i.block,
        });

        if (result.status === 200) {
          Swal.fire({
            icon: 'success',
            title: '예약이 취소되었습니다.',
            timer: 2000,
          }).then(() => {
            getData();
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: '예약 취소중 에러가 발생했습니다. 다시 시도해주세요',
            timer: 2000,
          }).then(() => {
            getData();
          });
        }
      }
    });
  };

  const currentPosts = (tmp) => {
    let currentPosts = 0;
    currentPosts = tmp.slice(indexOfFirst, indexOfLast);
    return currentPosts;
  };

  const searchData = () => {
    if (!searchKeyword) {
      Swal.fire({ icon: 'error', title: '검색어를 입력해주세요', timer: 200 });
    } else {
      let copyData = [...defaultList];
      let searchData = copyData.filter(
        (item) =>
          item.phoneNum?.includes(searchKeyword) ||
          item.start?.includes(searchKeyword) ||
          item.end?.includes(searchKeyword),
      );
      setReservationData(searchData.filter((item) => item.seminarId === seminarId));
      setCurrentPage(1);
    }
  };

  const handleSearchKeyword = (keyword) => {
    if (keyword === '') {
      setSearchKeyword(null);
    } else {
      setSearchKeyword(keyword);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (!mid) return;
    getSeminarList(mid);
  }, [mid, startDate, endDate, seminarId]);
  return (
    <>
      <PageContainer title="세미나실 예약 확인">
        <TableHeaderContainerV2>
          <Dropdown>
            <select
              className="seminar_name"
              value={seminarId}
              onChange={(e) => setSeminarId(+e.target.value)}>
              {seminarData.map((item, index) => (
                <Fragment key={item.id}>
                  <option value={item.id} defaultChecked={(index = 0)}>
                    {item.name}
                  </option>
                </Fragment>
              ))}
            </select>
          </Dropdown>
          <Search
            searchKeyword={searchKeyword}
            setSearchKeyword={handleSearchKeyword}
            onSubmit={searchData}
          />
          {/* <Form
            style={{
              width: window.innerWidth > 480 ? '30%' : '100%',
            }}
            onSubmit={(e) => {
              e.preventDefault();
              searchData();
            }}>
            <LabelRow className="m-0">
              <Input
                className="form-control"
                type="text"
                placeholder="검색어를 입력해주세요"
                defaultValue={searchKeyword}
                onChange={(e) => handleSearchKeyword(e.target.value)}
              />

              <i onClick={searchData} className="fa fa-search" />
            </LabelRow>
          </Form> */}
        </TableHeaderContainerV2>
        <Table
          data={currentPosts(reservationData)}
          columns={seminarReserved1}
          striped={true}
          center={true}
        />
        {reservationData.length > 0 && (
          <PaginationComponent
            setCurrentPage={setCurrentPage}
            setPostsPerPageAdd={setPostsPerPageAdd}
            setPostsPerPageAdd1={setPostsPerPageAdd1}
            setPageNum={setPageNum}
            totalLength={
              // searchData.length >= 1
              //   ? searchData.length:
              reservationData.length
            }
            postsPerPage={postsPerPage}
            currentPage={currentPage}
            pageNum={pageNum}
            postsPerPageAdd={postsPerPageAdd}
            postsPerPageAdd1={postsPerPageAdd1}
          />
        )}
      </PageContainer>
    </>
  );
};

export default SeminarInfo;
