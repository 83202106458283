import { seat, seatWithMerchantToken } from '.';

/**
 * 좌석 카테고리 등록
 * @param formdata seatCategoryImage, seatCategoryName
 * @returns
 */
export const postSeatCategory = (formdata: FormData) => seat.post(`/category`, formdata);

/**
 * 좌석 카테고리 목록 조회
 * @returns
 */
export const getSeatCategory = () => seat.get(`/category`);

/**
 * 좌석 카테고리 수정
 * @param formdata seatCategoryImage, seatCategoryName
 * @returns
 */
export const patchSeatCategory = (formdata: FormData) => seat.patch(`/category`, formdata);

/**
 * 좌석 카테고리 삭제
 * @param seatCategoryId number
 * @returns
 */
export const deleteSeatCategory = (seatCategoryId: number) =>
  seat.delete(`/category/${seatCategoryId}`);

/**
 * 이용권이 점유 가능한 좌석 목록
 * @param ticketItemId
 * @returns
 */
export const getSeatList = (ticketItemId: number) =>
  seatWithMerchantToken.get(`/list/${ticketItemId}`);

/**
 * 퇴실 예정석 조회
 * @returns
 */
export const getRemainTime = () => seatWithMerchantToken.get(`/remainTime`);
